import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import useToggle from '@hooks/useToggle/useToggle'

import { RegionalMenuContainer } from './styles'

const MenuContainer = dynamic(() => import('./components/MenuContainer'))
const RegionalMenuPopover = dynamic(() => import('./components/RegionalMenuPopover'))

type Props = {
  placement?: TooltipPlacement
  variant?: 'classic' | 'default'
}

export const RegionalMenu: FC<Props> = ({ placement = 'bottomRight', variant = 'default' }) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)
  const { on: isOpenRegionalMenu, setValue: setIsOpenRegionalMenu, toggleOff: closeRegionalMenu } = useToggle()

  return (
    <RegionalMenuContainer>
      <MenuContainer isActive={isOpenRegionalMenu} variant={variant} />
      {isPageInteraction && (
        <RegionalMenuPopover
          close={closeRegionalMenu}
          isOpen={isOpenRegionalMenu}
          placement={placement}
          setIsOpen={setIsOpenRegionalMenu}
        />
      )}
    </RegionalMenuContainer>
  )
}
